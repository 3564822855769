<template>
  <b-modal
    :id="identifier"
    hide-header
    hide-footer
    centered
    size="lg"
    @show="onShowModal"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">{{ disabled ? 'Detalhes do ' : transaction ? 'Atualizar ' : 'Adicionar ' }}produto</div>
      <span class="icon-box"><Close class="icon stroke" @click="closeModal" /></span>
    </div>

    <div class="row md-content">
      <b-row class="w-100">
        <b-col cols="4">
          <b-form-group class="w-100">
            <label for="barcode">
              Código de barras (opcional)
            </label>
            <div class="barcode-input">
              <b-form-input
                id="barcode"
                v-model="form.barcode"
                autocomplete="off"
                placeholder="Descrever"
              />
              <Barcode class="barcode" />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group>
            <label for="batch">Produto</label>
            <multiselect
              v-model="form.product"
              track-by="id"
              label="name"
              placeholder="Selecionar"
              :options="products"
              :searchable="true"
              :allow-empty="false"
              :option-height="40"
              :showLabels="false"
              :showNoResults="true"
              class="with-border"
              :disabled="disabled"
              @search-change="getProducts"
              @select="loadDistinctBatches"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>

              <template slot="noOptions">Digite para pesquisar um produto</template>
              <template slot="noResult">Nenhum produto encontrado</template>
            </multiselect>
            <div v-if="verified && !form.product" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="w-100">
        <div class="col-4">
          <b-form-group>
            <label for="batch">
              Lote
              <span v-if="!this.configuration.batch_control_required" class="help">(opcional)</span>
            </label>
            <b-form-input
              v-if="type !== 'OUTPUT'"
              autocomplete="off"
              id="batch"
              trim
              placeholder="Inserir lote"
              v-model="form.batch"
              @input.native="getBatchDebounce"
              :disabled="disabled"
            />
            <Autocomplete
              v-else
              trim
              autocomplete="off"
              placeholder="Selecionar"
              emptyResultsMessage="Nenhum lote encontrado para o produto selecionado"
              v-model="form.batch"
              :options="batches"
              @input="loadBatch"
            />
            <div v-if="verified && !form.batch && this.configuration.batch_control_required"
                 class="custom-invalid-feedback">Campo obrigatório
            </div>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group id="field_manufacturing_date">
            <label for="manufacturing_date">
              Data de fabricação
              <span class="help">(opcional)</span>
            </label>
            <date-picker
              id="manufacturing_date"
              class="full"
              v-model="form.manufacturing_date"
              format="DD/MM/YYYY"
              placeholder="DD/MM/AAAA"
              :clearable="true"
              :lang="langDatePicker"
              :disabled-date="disabledDates"
              :disabled="disabled || type === 'OUTPUT'"
            ></date-picker>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group>
            <label for="date">
              Data de validade
            </label>
            <date-picker
              id="expiration_date"
              class="full"
              v-model="form.expiration_date"
              format="DD/MM/YYYY"
              placeholder="DD/MM/AAAA"
              :clearable="true"
              :lang="langDatePicker"
              :disabled-date="disabledValidityDates"
              :disabled="disabled || type === 'OUTPUT'"
            ></date-picker>
            <div v-if="verified && !form.expiration_date" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </div>
      </b-row>
      <b-row class="w-100">
        <div class="col-4">
          <b-form-group
            :invalid-feedback="'Campo obrigatório'"
            :state="isValidStateNumber(form.quantity)"
          >
            <label for="quantity">Quantidade</label>
            <b-form-input
              ref="quantity"
              autocomplete="off"
              id="quantity"
              trim
              type="number"
              min="1"
              placeholder="Inserir quantidade"
              v-model="form.quantity"
              :disabled="disabled"
              @input="value => (form.quantity = !!value && Math.abs(value) >= 0 ? Math.abs(value) : null)"
            />
            <div
              class="custom-invalid-feedback"
              v-if="verified &&
                type === 'OUTPUT' &&
                form.batch_balance &&
                (form.batch_balance.balance < form.quantity || form.batch_balance < form.quantity)"
            >
              {{ `Apenas ${form.batch_balance.balance ? form.batch_balance.balance : form.batch_balance.balance} unidades em estoque`
              }}
            </div>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            :invalid-feedback="'O custo deve ser maior que zero'"
            :state="isValidStateNumber(form.purchase_price)"
          >
            <label for="purchase_price">Custo unitário</label>
            <b-form-input
              autocomplete="off"
              id="purchase_price"
              v-model.lazy="form.purchase_price"
              v-money="money"
              :disabled="disabled || (type === 'OUTPUT' && this.configuration?.autocomplete_purchase_price)"
            />
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            :invalid-feedback="'O valor deve ser maior que zero'"
            :state="isValidStateNumber(form.sale_price)"
          >
            <label for="sale_price">Valor de venda unitário</label>
            <b-form-input
              autocomplete="off"
              id="sale_price"
              v-model.lazy="form.sale_price"
              v-money="money"
              :disabled="disabled"
            />
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group id="field_icms_calc_base">
            <label for="icms_calc_base" class="title">
              Base cál. ICMS
              <span class="help optional">(opcional)</span>
            </label>
            <b-form-input
              autocomplete="off"
              class="form-control"
              id="icms_calc_base"
              v-money="money"
              v-model="form.icms_calc_base"
              @blur.native="calcTaxesByBase"
            />
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group id="field_icms_value">
            <label for="icms_value" class="title">
              Alíquota ICMS
              <span class="help optional">(opcional)</span>
            </label>
            <b-input-group append="%">
              <b-form-input
                autocomplete="off"
                id="icms_quota"
                v-model="form.icms_quota"
                @change="calcTaxesByQuota"
                type="number"
                style="border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;"
              />
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group id="field_icms_value">
            <label for="icms_value" class="title">
              Valor do ICMS
              <span class="help optional">(opcional)</span>
            </label>
            <b-form-input
              autocomplete="off"
              class="form-control"
              id="icms_value"
              v-money="money"
              v-model="form.icms_value"
              @blur.native="calcTaxesByValue"
            />
          </b-form-group>
        </div>

        <div class="col-12">
          <hr />
        </div>

        <div class="col-12">
          <b-form-group class="form-observations">
            <label for="observations">
              Observações
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              autocomplete="off"
              id="observations"
              trim
              placeholder="Adicionar observações"
              v-model="form.observations"
              :disabled="disabled"
            />
          </b-form-group>
        </div>
      </b-row>
    </div>

    <div v-if="!disabled" class="md-button">
      <b-button
        class="wh-button"
        variant="primary"
        size="lg"
        :disabled="false"
        @click="transaction?.id ? updateTransaction() : onSave()"
      >
        {{ transaction ? 'Atualizar e voltar' : 'Salvar e voltar' }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import { VMoney } from 'v-money'
import { parseMoneyToNumber, parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../utils/localStorageManager'
import { debounce } from 'lodash'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Autocomplete: () => import('@/components/Autocomplete'),
    Barcode: () => import('@/assets/icons/barcode.svg')
  },
  props: {
    identifier: String,
    transaction: Object,
    disabled: [Boolean, String],
    type: String,
    useType: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      configuration: {},
      query: '',
      products: [],
      batches: [],
      verified: false,
      form: {},
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      getBatchDebounce: debounce(this.loadBatch, 500),
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    onShowModal() {
      this.getWarehouseConfigurations()
      this.getProducts()
    },
    getProducts(query) {
      this.api.getProducts(this.clinic.id, 1, query)
        .then(res => {
          this.products = res.data.data
        })
        .catch(err => this.$toast.error(err.message))
    },
    calcTaxesByValue() {
      if (this.form.icms_calc_base) {
        this.form.icms_quota = (parseMoneyToNumber(this.form.icms_value) * 100) / parseMoneyToNumber(this.form.icms_calc_base)
      } else if (this.form.icms_quota) {
        this.form.icms_calc_base = parseNumberToMoney((this.form.icms_value * 100) / this.form.icms_quota)
      }
    },
    calcTaxesByBase() {
      if (this.form.icms_quota) {
        this.form.icms_value =  parseNumberToMoney((this.form.icms_calc_base * this.form.icms_quota) / 100)
      } else if (this.form.icms_value) {
        this.form.icms_quota = (parseMoneyToNumber(this.form.icms_value) * 100) / parseMoneyToNumber(this.form.icms_calc_base)
      }
    },
    calcTaxesByQuota() {
      if (this.form.icms_calc_base) {
        this.form.icms_value =  parseNumberToMoney((this.form.icms_quota * parseMoneyToNumber(this.form.icms_calc_base)) / 100)
      } else if (this.form.icms_value) {
        this.form.icms_calc_base =  parseNumberToMoney((parseMoneyToNumber(this.form.icms_value) * 100) / this.form.icms_quota)
      }
    },
    async updateTransaction() {
      this.verified = true
      if (!this.isValidForm()) return
      this.verified = false
      const isLoading = this.$loading.show()
      const props = {
        batch: this.form.batch,
        manufacturing_date: this.form.manufacturing_date || null,
        expiration_date: this.form.expiration_date,
        quantity: this.form.quantity,
        purchase_price: parseMoneyToNumber(this.form.purchase_price),
        sale_price: parseMoneyToNumber(this.form.sale_price),
        icms_value: parseMoneyToNumber(this.form.icms_value),
        icms_calc_base: parseMoneyToNumber(this.form.icms_calc_base),
        icms_quota: this.form.icms_quota,
        observations: this.form.observations
      }
      await this.api.updateWarehouseTransaction(this.transaction?.id, props)
        .then(() => {
          isLoading.hide()
          this.$toast.success('Produto atualizado com sucesso!')
          this.$emit('onUpdate', Object.assign(this.form, props))
          this.closeModal()
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => isLoading.hide())
    },
    async loadBatch() {
      try {
        const data = await this.getTransactionByProductAndBatch()
        if (data && data.id) {
          this.form = {
            ...data,
            id: null,
            quantity: this.form.quantity,
            expiration_date: new Date(data.expiration_date),
            manufacturing_date: data.manufacturing_date ? new Date(data.manufacturing_date) : null,
            purchase_price: parseNumberToMoney(this.configuration.autocomplete_purchase_price ? data.purchase_price : 0),
            sale_price: parseNumberToMoney(this.useType !== 'DISCARD' && this.configuration.autocomplete_purchase_price ? data.sale_price : 0),
            icms_value: parseNumberToMoney(data.icms_value || 0),
            icms_calc_base: parseNumberToMoney( data.icms_calc_base || 0),
            icms_quota: data.icms_quota || 0,
            observations: this.form.observations
          }
          this.$refs.quantity.focus()
        } else {
          this.form = {
            ...this.form,
            expiration_date: null,
            manufacturing_date: null,
            purchase_price: parseNumberToMoney(0),
            sale_price: parseNumberToMoney(0),
            icms_value: parseNumberToMoney(0),
            icms_calc_base: parseNumberToMoney(0),
            icms_quota: 0
          }
        }
      } catch (err) {
        console.log('Err ', err)
      }
    },
    async loadDistinctBatches(product) {
      if (this.type !== 'OUTPUT') return
      const isLoading = this.$loading.show()
      const data = await this.getDistinctProductBatchNumbers(product?.id)
      this.batches = data.map(el => ({ label: el.batch, date: new Date(el.expiration_date), value: el.batch }))
      isLoading.hide()
    },
    getWarehouseConfigurations() {
      return new Promise((resolve, reject) => {
        this.api.getWarehouseConfigurations(this.clinic.id)
          .then(res => {
            this.configuration = res.data
            resolve(res.data)
          })
          .catch(reject)
      })
    },
    getTransactionByProductAndBatch() {
      return new Promise((resolve, reject) => {
        if (!this.form.product || !this.form.batch) resolve(null)
        this.api
          .getTransactionByProductAndBatch(this.form.product.id, this.form.batch)
          .then((res) => resolve(res.data))
          .catch(reject)
      })
    },
    getDistinctProductBatchNumbers(productId) {
      return new Promise((resolve, reject) => {
        this.api.getDistinctProductBatchNumbers(productId)
          .then((res) => resolve(res.data))
          .catch(reject)
      })
    },
    isValidState(model) {
      return !this.verified || (model !== 'undefined' && model !== null && model !== '')
    },
    isValidStateNumber(model) {
      if (typeof model === 'string') {
        model = parseMoneyToNumber(model)
      }
      return !this.verified || (model !== 'undefined' && model !== null && model > 0)
    },
    isValidForm() {
      let isValid = true
      if (!this.form.product) {
        isValid = false
      }
      if (!this.form.batch && this.configuration.batch_control_required) {
        isValid = false
      }
      if (!this.form.expiration_date) {
        isValid = false
      }
      if (!this.form.quantity) {
        isValid = false
      }
      if (!this.form.purchase_price || this.form.purchase_price === `R$ 0,00`) {
        isValid = false
      }
      if (!this.form.sale_price || this.useType === 'SALE' && this.form.sale_price === `R$ 0,00`) {
        isValid = false
      }
      if (!this.useType === 'SALE' && this.form.sale_price === `R$ 0,00`) {
        isValid = true
      }

      const balance = this.form.batch_balance?.balance ? this.form.batch_balance.balance : 0

      if (this.type === 'OUTPUT' && parseInt(this.form.quantity) > 0 && (parseInt(this.form.quantity) > balance)) {
        isValid = false
      }
      
      return isValid
    },
    onSave() {
      this.verified = true
      if (!this.isValidForm()) return
      this.form.purchase_price = parseMoneyToNumber(this.form.purchase_price)
      this.form.sale_price = parseMoneyToNumber(this.form.sale_price)
      this.form.icms_value = parseMoneyToNumber(this.form.icms_value)
      this.form.icms_calc_base = parseMoneyToNumber(this.form.icms_calc_base)
      this.$emit('onSave', this.form)
      this.verified = false
      this.closeModal()
    },
    closeModal() {
      this.form = {}
      this.verified = false
      this.$bvModal.hide(this.identifier)
      this.$emit('onHide')
    },
    disabledDates(date) {
      return date > new Date()
    },
    disabledValidityDates(date) {
      return date < new Date()
    }
  },
  watch: {
    transaction: function(newValue) {
      if (newValue) {
        this.form = {
          ...newValue,
          quantity: newValue.quantity,
          expiration_date: new Date(newValue.expiration_date),
          manufacturing_date: newValue.manufacturing_date ? new Date(newValue.manufacturing_date) : null,
          purchase_price: parseNumberToMoney(newValue.purchase_price),
          sale_price: parseNumberToMoney(newValue.sale_price),
          icms_value: parseNumberToMoney(newValue.icms_value),
          icms_calc_base: parseNumberToMoney(newValue.icms_calc_base),
          icms_quota: newValue.icms_quota,
          observations: newValue.observations
        }
        this.loadDistinctBatches(newValue.product)
      } else {
        this.form = {}
      }
    }
  },
  directives: { money: VMoney }
}
</script>
<style lang="scss" scoped>
.modal-header {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  font-size: 18px;
  color: var(--dark-blue);
  font-weight: 600;
}

.icon {
  width: 25px;
}

.md-content {
  padding: 30px 20px 10px 20px;
}

.form-observations {
  margin-top: 20px;
}

.md-button {
  text-align: right;
  margin-right: 20px;
  margin-bottom: 10px;
}

.balance-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--blue-500);
}

.barcode-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    position: relative;
  }

  .barcode {
    position: absolute;
    margin-left: 72%;
    transform: scale(0.8);
  }
}
</style>
